import {
    OnboardingComponent,
    IOnboardingComponent,
} from 'components/Onboarding/Onboarding';
import lifGraphLogo from '../../../assets/images/logos/footerLogo.svg'
import { themeBase } from 'util/themes';
import useDeviceType from 'util/useDeviceType';
const styles = {
    logoStyle: {
        display: 'flex',
        justifyContent: 'start'
    }
}

export function DataManagerOnboarding() {
    const { isMobile, isTablet } = useDeviceType();

    const data: IOnboardingComponent = {
        logoStyles: {
            ...styles.logoStyle,
            ...(!isMobile && {pt: '188px'}),
            [themeBase.breakpoints.down('md')]:{
                paddingLeft:'27px',
                paddingTop: '130px',
                'img':{
                    height: '44px',
                    width: '188px',
                }
            }
        },
        lifeGraphLogo: !isTablet && lifGraphLogo,
        redirectLink: '/data-manager',
        header: `Take Control of Your Data`,
        subHeader: `Because you should be in the driver’s seat`,
        bottomTextList: [
            `LifeGraph enables data dignity by giving you the power to choose what data to share, who to share it with, and for how long. This control gives you the transparency and trust you need to put your data to work.`,
            `If you’d like to learn more about the genius technology, check out the full details here.`,
        ],
        bottomTextStyle: {
            lineHeight: '27px',
            [themeBase.breakpoints.down('md')]:{
                fontSize:'16px',
                fontWeight:400,
                fontFamily:'Nunito',
                pl: '26.63px',
                pr: '44.79px',
                mb: '14.31px',
            },
        },
        buttonText: `Manage Your Data`,
        onboardingScreen: 'dataManager',
        backgroundStyle: {
            backgroundGradient: {
                background: 'linear-gradient(137.83deg, #1E4993 -6.19%, #2869D8 38.32%, #3EB9C2 96.77%);',
                [themeBase.breakpoints.down('md')]:{
                    minHeight:'100vh',
                },
            },
            background: {
                height: `${isTablet ? '100vh' : '100%'}`,
                color: '#E9EAFF',
                '&.datamanagerOnboarding': {
                    background: `url('/images/data-manager/datamanager_onboarding.svg') no-repeat`,
                    backgroundSize: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    [themeBase.breakpoints.down('md')]:{
                        display:'block',
                        background:`url('/images/data-manager/data-manager-onboarding-mobile.svg') no-repeat`,
                        backgroundSize:'contain',
                    },
                    [themeBase.breakpoints.between('md', 'lg')]:{
                        pl: '140px',
                        pt: '20px',
                    },
                },
                '& .MuiContainer-root':{
                    [themeBase.breakpoints.down('md')]:{
                        padding: 0,
                    },
                }
            },
            backgroundClass: 'datamanagerOnboarding'
        },
        buttonStyle: {
            marginTop: '30px',
            width: '229px',
            height: '60px',
            borderRadius: '100px',
            padding: '0.875em 2em',
            background: 'linear-gradient(98deg, #4DD8E5 2.63%, #7AABFF 44.9%, #7781FF 93.81%)',
            color: '#003DA7',
            transition: 'background ease 1.5s',
            marginBottom: `${(!isMobile || !isTablet) && '188px'}`,
            '&:hover, &:focus': {
                background: 'linear-gradient(126deg, #A8F6FF 0%, #B388FF 100%)',
                color: '#003DA7',
                outline: '2px solid rgba(255, 255, 255, 0.5)',
                outlineOffset: '5px',
            },
            '> .MuiTouchRipple-root span': {
                background: 'radial-gradient(36.59% 100.80% at 50.00% 50.00%, rgba(255, 255, 255, 0.30) 99.54%, rgba(255, 255, 255, 0.00) 100%)'
              },
            [themeBase.breakpoints.down('md')]:{
                marginTop:'20px',
                height:'53px',
                width:'164px',
                padding:'15.4px 13.5px',
                marginBottom:1,
                paddingTop:2,
                ml: '26.63px',
            },
        },
        buttonTextStyle: {
            letterSpacing: 'normal',
            fontWeight: 700,
            color: '#003DA7',
            [themeBase.breakpoints.down('md')]:{
                fontFamily: 'Nunito sans',
                fontSize:'16px',
                color: '#FFF'
            },
        },
        headerTextStyle:{
            [themeBase.breakpoints.down('md')]:{
                fontSize:'30px',
                fontWeight:400,
                pl: '26.63px',
                pr: 1,
                mb:'14.41px',
            },
            [themeBase.breakpoints.between('md', 'lg')]:{
                mt: '177px'
            },
        },
        subHeaderTextStyle: {
            [themeBase.breakpoints.down('md')]:{
                fontSize:'28px',
                fontWeight:300,
                pl: '26.63px',
                pr: '43.37px',
                mb:'28.31px',
            }
        }
    };
    return <OnboardingComponent {...data} />;
}
