import React, { useContext, useEffect } from 'react';
import { Box, Grid, Container, Typography, Button } from '@mui/material';
import { PageHeader } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { pathwayActionCreators } from 'redux/actionCreators';
import { IPathway } from '../../../@types/pathway-types';
import { pathwaySelector ,personalInfoSelector} from 'redux/selectors';
import { checkOnboarding } from '../../../util/getUserData';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'util/themes/themeContext';
import { CompletedPathwaySummary } from '../components/summary/PathwaySummary';
import { MyPathwaySummary } from './../components/summary/PathwaySummary';
import { CompletedPathwaySummaryLoading, MyPathwaySummaryLoading } from '../components/summary/PathwaySummaryLoading';
import useDeviceType from 'util/useDeviceType';
import { meryville, themeBase } from 'util/themes';

const styles = {
    root: {
        pt: 2,
        px: { xs: 0 },
        pb: '30px',
    },
    emptyStateHeader: {
        fontSize: '64px',
        lineHeight: '77px',
        marginBottom: '12px'
    },
    button: {
        textTransform: 'none',
        borderRadius: '100px',
        background: 'linear-gradient(98.23deg, #4DD8E5 2.63%, #7AABFF 44.9%, #7781FF 93.81%)',
        '&:hover, &:focus': {
            background: 'linear-gradient(126deg, #A8F6FF 0%, #B388FF 100%)',
            color: '#003DA7',
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.80% at 50.00% 50.00%, rgba(255, 255, 255, 0.30) 99.54%, rgba(255, 255, 255, 0.00) 100%)'
          }
    },
    buttonText: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '33px',
        color: meryville.palette.primary[600],
    },
    emptyStateBackground: {
        padding: '0px !important',
        display: 'flex',
        flexDirection: 'column',
        backgroundPosition: 'right'
    },
    exploreMarketplaceBtn: {
        fontSize: '15px',
        lineHeight: '20px',
        textTransform: 'capitalize',
        ml: '7px'
    },
    headerStyle: {
        fontFamily: 'Museo Sans Rounded',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '32px',
        [themeBase.breakpoints.down('md')]:{
            fontSize: "18px",
        },
    }
};

export function PathwayList() {
    const dispatch = useDispatch();
    const history = useHistory();
    const myPathways = useSelector(pathwaySelector.selectUserPathways);
    const loading = useSelector(pathwaySelector.selectPathwayLoading);
    const { selectedTheme } = useContext(ThemeContext);
    const pathwayInprogress = myPathways.filter((p: IPathway) => (p?.instanceData?.status === 'COMING_UP' || p?.instanceData?.status === 'IN_PROGRESS'))
    const completedPathways = myPathways.filter((p: IPathway) => (p?.instanceData?.status === 'COMPLETED'))
    const { isMobile, isTablet } = useDeviceType();

    const personalInfo = useSelector(
        personalInfoSelector.selectPersonalInfoData
    );

    useEffect(() => {
        if (!checkOnboarding('pathway')) {
            setTimeout(() => {
                history.push('/pathway/onboarding');
            }, 0);
        }
        if (checkOnboarding('pathway') && personalInfo?.asset_id) {
            dispatch(pathwayActionCreators.getPathwayData(personalInfo.asset_id));
        }
    }, [personalInfo.asset_id]);

    let backgroundUrl;

    if (isMobile) {
        backgroundUrl = pathwayInprogress.length
            ? 'url(/images/pathways/myPathwaysBackgroundMobile.svg)'
            : 'url(/images/pathways/emptyStatePathwayBgMobile.svg)';
        } else {
        backgroundUrl = 'url(/images/pathways/emptyStatePathwayBg.svg)';
    }

    const backgroundStyles = {
        background: backgroundUrl,
        height: 'initial',
        backgroundSize: 'contain',
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
        [meryville.breakpoints.down('md')]: {
            backgroundPosition: 'top',
            backgroundSize: 'initial',
        },
        [meryville.breakpoints.between('md', 'lg')]: {
            backgroundPosition: 'right',
            backgroundSize: 'initial',
        },
    };

    const getPaddingForContainer = () => {
        if(isMobile) {
            return '16px 32px 0px 32px'
        }else if(isTablet){
            return '20px 60px 0px 60px'
        }else {
            return '16px 41px 0px 42px'
        }
    }; 

    const getBackgroundStyle = () =>{
        if(!pathwayInprogress.length && !loading){
            return backgroundStyles
        }
    }

    const renderEmptyState = () => {
        return (
            <Box sx={{ ...styles.emptyStateBackground }}>
                <Box>
                    <Typography
                        sx={{
                            ...styles.emptyStateHeader,
                            ...selectedTheme?.typography?.h1,
                            color: selectedTheme?.palette?.primary?.contrast,
                            lineHeight: 'normal',
                            [themeBase.breakpoints.down('md')]: {
                                fontSize: '32px',
                            },
                            [themeBase.breakpoints.between('md', 'lg')]: {
                                fontSize: '64px',
                            },
                        }}
                    >
                        Lets go on a journey
                    </Typography>
                    <Typography
                        sx={{
                            ...selectedTheme?.typography?.body?.default,
                            ...selectedTheme?.typography?.body?.body3,
                            color: selectedTheme?.palette?.secondary
                                ?.contrastText,
                            mb: `${isMobile ? '25px' : '43px'}`,
                            lineHeight: 'normal',
                            [themeBase.breakpoints.down('md')]: {
                                fontSize: '16px',
                            },
                        }}
                    >
                        You dont have any active pathways. Check out the <br />
                        recommendations below or visit the pathway marketplace
                        to start <br />a journey.
                    </Typography>

                    <Button
                        variant="contained"
                        size="large"
                        sx={{
                            ...styles.button,
                            width: `${isMobile ? '217px' : '270px'}`,
                            height: `${isMobile ? '54px' : '58px'}`,
                            ...(isMobile && { mb: '35px' }),
                        }}
                        color={'primary'}
                    >
                        <Typography
                            sx={{
                                ...selectedTheme?.typography?.Components?.button
                                    ?.default,
                                ...selectedTheme?.typography?.Components?.button
                                    ?.medium,
                                textTransform: 'none',
                                ...styles.buttonText,
                                [themeBase.breakpoints.down('md')]: {
                                    fontSize: '18px',
                                },
                                whiteSpace: 'nowrap',
                                lineHeight: 'normal',
                            }}
                        >
                            Pathway Marketplace
                        </Typography>
                    </Button>
                </Box>
            </Box>
        );
    };

    return (
        <Box>
            <Box sx={{  p: getPaddingForContainer(), ...getBackgroundStyle()}}>
                <Container maxWidth={false} disableGutters>
                        {isMobile && (
                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'} mb={isMobile?'41px':2.75}>
                                <Box mt={'4px'}>
                                    <img src='/images/pathways/bluePathwayBtnImgMobile.svg' alt=''/>
                                </Box>
                                <Box ml={'6px'}>
                                    <Typography sx={{
                                        ...styles.headerStyle,
                                        color: selectedTheme?.palette?.primary[10]
                                    }}>My Pathways</Typography>
                                </Box>
                            </Box>
                        )}
                    <Box sx={{ px: { xs: 1, md: 0 }}}>
                        {!pathwayInprogress.length && !loading ? (
                            renderEmptyState()
                        ) : (
                            <></>
                        )
                        }
                    </Box>
                    {(!checkOnboarding('pathway') || loading) ? (
                        <MyPathwaySummaryLoading mode={'SUMMARY'} />
                    ) : (
                        React.Children.toArray(
                            pathwayInprogress.map((p: IPathway) => (
                                <MyPathwaySummary
                                    assetId={p?.assetId}
                                    metadata={p?.metaData}
                                    instancedata={p?.instanceData}
                                    pathwaySkillData={p?.pathwaySkillData}
                                    mode="summary"
                                    isMobile={isMobile}
                                />
                            ))
                        )
                    )}
                </Container>
            </Box>

            {/* ------Commenting this Explore pathway section as we wont display it ON MVP ------- */}
            {/* <Box sx={{ pb: '30px', pt: '12px' }}>
                <Grid item xs={12} md={4} lg={3} >
                    <Box sx={{ px: { xs: 3, md: 0 } }}>
                        <PageHeader
                            title="Explore Pathways"
                            sx={{
                                ...selectedTheme?.typography?.h2,
                                color: selectedTheme?.palette?.secondary?.grayScale['100']

                            }}
                        />
                        {!otherPathways.length && !loading ? (
                            <Box>
                                <Typography
                                    sx={{
                                        ...selectedTheme?.typography?.body?.default,
                                        ...selectedTheme?.typography?.body?.body1,
                                        color: selectedTheme?.palette?.secondary
                                            ?.grayScale?.main,
                                    }}
                                >
                                    There are no new pathways to explore.
                                </Typography>
                            </Box>
                        ) : (
                            ''
                        )
                        }
                    </Box>

                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '20px'
                    }}>
                        {loading ? (
                            <ExplorePathwaySummaryLoading mode={'SUMMARY'} />
                        ) : (
                            React.Children.toArray(
                                otherPathways.map((p: IPathway) => (
                                    <Box >
                                        <ExplorePathwaySummary
                                            assetId={p?.assetId}
                                            metadata={p?.metaData}
                                            instancedata={p?.instanceData}
                                            mode="summary"
                                        />
                                    </Box>
                                ))
                            )
                        )}
                    </div>
                </Grid>
                <Box sx={{
                    mt: '16px', justifyContent: 'flex-end',
                    display: 'flex'
                }}>
                    <Button
                        onClick={() => { }}
                        className="explore_marketplace"
                    >
                        <img src={'/images/sideNavBar/pathways_active.svg'} alt="explore_marketplace" width='20px'
                            height='20px'
                        />
                        <Typography sx={{
                            ...selectedTheme?.typography?.body?.default,
                            ...selectedTheme?.typography?.body?.bold,
                            color: selectedTheme?.palette?.secondary?.light,
                            ...styles.exploreMarketplaceBtn
                        }}>
                            Pathway Marketplace
                        </Typography>
                    </Button>
                </Box>
            </Box> */}

            {completedPathways.length > 0 && (
                <Box sx={{ p: getPaddingForContainer() }}>
                    <Grid item xs={12} md={4} lg={3}>
                        <Box sx={{ px: { xs: 1, md: 0 } }}>
                            <PageHeader
                                title="Completed Pathways"
                                sx={{
                                    ...selectedTheme?.typography?.h2,
                                    color: selectedTheme?.palette?.secondary
                                        ?.grayScale['100'],
                                }}
                            />
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                gap: '24px',
                            }}
                        >
                            <Grid
                                container
                                columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
                            >
                                {(!checkOnboarding('pathway') || loading) ? (
                                    <CompletedPathwaySummaryLoading
                                        mode={'SUMMARY'}
                                    />
                                ) : (
                                    React.Children.toArray(
                                        completedPathways.map((p: IPathway) => (
                                            <Box sx={{ padding: '7px' }}>
                                                <CompletedPathwaySummary
                                                    assetId={p?.assetId}
                                                    metadata={p?.metaData}
                                                    instancedata={
                                                        p?.instanceData
                                                    }
                                                    mode="summary"
                                                />
                                            </Box>
                                        ))
                                    )
                                )}
                            </Grid>
                        </Box>
                    </Grid>
                </Box>
            )}
        </Box>
    );
}