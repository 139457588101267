import {
    Grid,
    Typography,
    Box,
    Container,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { styles } from './onboarding.helper';
import { updateOnboarding } from '../../util/getUserData';
import { Children, useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import { Button } from 'lib/controls';
import { useAuth } from 'util/AuthContext';
import useDeviceType from 'util/useDeviceType';
import MobileDrawer from 'components/header/MobileDrawer';

export interface IOnboardingComponent {
    logoStyles?: any;
    lifeGraphLogo?: any;
    redirectLink: string;
    header: string;
    subHeader: string;
    bottomTextList: string[];
    buttonText: string;
    onboardingScreen: string;
    bottomTextStyle?: any;
    backgroundStyle?: any;
    headerTextStyle?: any,
    subHeaderTextStyle?: any,
    buttonStyle?: any,
    buttonTextStyle?: any,
}

export interface IOnboarding {
    pathway?: string;
    dataManager?: string;
    profile?: string;
}

export const OnboardingComponent = (props: IOnboardingComponent) => {
    const {
        logoStyles,
        lifeGraphLogo,
        redirectLink,
        header,
        subHeader,
        bottomTextList,
        buttonText,
        onboardingScreen,
        bottomTextStyle,
        backgroundStyle,
        headerTextStyle = {},
        subHeaderTextStyle = {},
        buttonStyle = {},
        buttonTextStyle = {},
    } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const { isMobile, isTablet } = useDeviceType();
    const { userPreferences, setUserPreferences, currentTitle, setCurrentTitle } = useAuth()
    const history = useHistory();

    const onButtonClick = () => {
        let { id } = userPreferences;
        let updatedPreferences = { ...userPreferences };
        updatedPreferences[onboardingScreen] = true;
        updateOnboarding(updatedPreferences, id, setUserPreferences).then(() => {
            // if we give directly then it will not work due to some issue with ConnectedRouter
            if (!redirectLink || redirectLink === '') {
                return;
            }

            setTimeout(() => {
                history.push(redirectLink);
            }, 0);
        });
    };
    const backgroundClass = backgroundStyle?.backgroundClass || '';

    return (
            <Box sx={backgroundStyle?.backgroundGradient} >
                {(isMobile || isTablet) && <MobileDrawer setCurrentTitle={setCurrentTitle} currentTitle={currentTitle}/>}
                <Box sx={backgroundStyle?.background} className={backgroundClass} >
                    <Box>
                        <Container>
                            <Grid container direction={'row'} >
                                <Grid
                                    item
                                    xs={12}
                                    alignSelf={'flex-end'}
                                    mb={isMobile? 0 : 3.125}
                                    mt={{ xs: 2, md: 0 }}
                                >
                                    {!!lifeGraphLogo && <Grid item sx={logoStyles} mb={{ sm: '10px' }}>
                                        <img
                                            src={lifeGraphLogo}
                                            height={'auto'}
                                            alt='ICON'
                                        ></img>
                                    </Grid>}
                                    {!!Object.values(headerTextStyle).length}
                                    <Typography sx={{ ...styles.header, ...headerTextStyle }}>{header}</Typography>
                                    {!!Object.values(subHeaderTextStyle).length}
                                    <Typography sx={{ ...styles.subHeader, ...subHeaderTextStyle }}>
                                        {subHeader}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                    <Container>
                        <Grid
                            container
                            direction={'row'}
                            sx={styles.extraSpaceAtBottom}
                        >
                            <Grid item xs={12} md={isTablet ? 10 : 6} >
                                {Children.toArray(
                                    bottomTextList.map((x: string) => {
                                        return (
                                            <Box sx={{ ...!isMobile && {mb:'30px'} }}>
                                                <Typography sx={{ ...styles.bottomText, ...bottomTextStyle }}>
                                                    {x}
                                                </Typography>
                                                {!!Object.values(bottomTextStyle).length}
                                            </Box>
                                        );
                                    })
                                )}
                                {
                                    buttonText ? (
                                        <Button
                                            onClick={onButtonClick}
                                            onTouchStart={onButtonClick}
                                            variant="contained"
                                            size="large"
                                            sx={{
                                                ...styles.button,
                                                ...buttonStyle
                                            }}
                                            color={'primary'}
                                        >
                                            <Typography sx={{
                                                ...selectedTheme?.typography?.Components?.button?.default,
                                                ...selectedTheme?.typography?.Components?.button?.medium,
                                                textTransform: 'none',
                                                ...styles.buttonText,
                                                ...buttonTextStyle
                                            }}>
                                                {buttonText}
                                            </Typography>
                                        </Button>
                                    ) : <></>
                                }
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
    );
};
