import {
    OnboardingComponent,
    IOnboardingComponent,
} from 'components/Onboarding/Onboarding';
import { meryville, themeBase } from 'util/themes';
import useDeviceType from 'util/useDeviceType';

export function PathwayOnboarding() {
    const { isMobile, isTablet } = useDeviceType();

    const getButtonMarginTop = () => {
        if (isMobile) {
            return '25px';
        } else if (isTablet) {
            return '0px';
        } else {
            return '43px';
        }
    };

    const getBackgroundImage = () => {
        if(isMobile){
            return `url(/images/onboarding/explore-pathways-background-mobile.svg) no-repeat`
        }else {
            return `url(/images/onboarding/explore-pathways-background.svg) no-repeat`
        }
    };

    const data: IOnboardingComponent = {
        redirectLink: '/pathway',
        header: `Let's go on a journey`,
        subHeader: `Create the career of your dreams.`,
        bottomTextList: [
            `Welcome to Pathways. Learning Pathways provide a roadmap for how you can achieve your career goals, so you can build skills and achievements that you own and can take with you, wherever your career takes you.`,
        ],
        bottomTextStyle: {
            lineHeight: 'normal',
            fontFamily: 'Nunito',
            [themeBase.breakpoints.down('md')]:{
                fontSize: "16px",
            }
        },
        buttonText: `Explore Pathways`,
        onboardingScreen: 'pathway',
        backgroundStyle: {
            backgroundGradient: {
                height:'100%',
                background:
                    'linear-gradient(287.27deg, #7781FF 1.85%, #0082CA 49.27%, rgba(0, 173, 187, 0) 94.82%)',
            },
            background: {
                height: `${isMobile ? '100vh' : 'inherit'}`,
                backgroundRepeat: 'no-repeat',
                color: '#E9EAFF',
                '&.pathwayOnboarding': {
                    background: getBackgroundImage(),
                    backgroundSize: `${isMobile ? 'cover' : '100%'}`,
                    display: 'flex',
                    flexDirection: 'column',
                    pt: `${isMobile ? '141px' : '232px'}`,
                    backgroundPosition: `${isMobile ? 'bottom' : 'center'}`,
                    pl: isTablet && '104px'
                },
            },
            backgroundClass: 'pathwayOnboarding',
        },
        buttonStyle: {
            marginTop: getButtonMarginTop(),
            height: `${isMobile ? '54px' : '80px'}`,
            width: `${isMobile ? '185px' : '273px'}`,
            borderRadius: '100px',
            padding: '0.875em 2em',
            background: 'linear-gradient(126deg, #A8F6FF 0%, #979FFF 100%)',
            color: '#003DA7',
            transition: 'background ease 1.5s',
            '&:hover, &:focus': {
                background: 'linear-gradient(126deg, #A8F6FF 0%, #B388FF 100%)',
                color: '#003DA7',
                outline: '2px solid rgba(255, 255, 255, 0.5)',
                outlineOffset: '5px',
            },
            '> .MuiTouchRipple-root span': {
                background: 'radial-gradient(36.59% 100.80% at 50.00% 50.00%, rgba(255, 255, 255, 0.30) 99.54%, rgba(255, 255, 255, 0.00) 100%)'
              }
        },
        buttonTextStyle: {
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: 'normal',
            color: meryville.palette.primary[600],
            [themeBase.breakpoints.down('md')]:{
                fontSize: "18px",
            },
            whiteSpace: 'nowrap',
        },
        headerTextStyle: {
            [themeBase.breakpoints.down('md')]:{
                fontSize: "32px",
                mb:'12px'
            },
            [themeBase.breakpoints.between('md','lg')]:{
                mb:'20px'
            }
        },
        subHeaderTextStyle: {
            [themeBase.breakpoints.down('md')]:{
                fontSize: "24px",
                mb:'25px'
            },
        },
    };
    return <OnboardingComponent {...data} />;
}
